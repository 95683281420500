
.check_page {
    margin-bottom: 100px;
}
.check_page_header {
    margin-top: 20px;
    margin-bottom: 30px;
}

.signup_page {
    margin-bottom: 100px;
}

.signup_page_header {
    margin-top: 20px;
    margin-bottom: 30px;
}

.chat_banner_arguments {
    margin-bottom: 15px;
}

.chat_banner_arguments strong {
    margin-left: 15px;
}

.c_tag {
    border-radius: 4px;
    background: #e2f4e8;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    color: #0ec123;
    padding: 4px 15px;
    margin: 10px 0px;
}

.signup-image {
    margin-top: 30px;
}

.signup_page_errors {
    text-align: left;
}

#section_welcome {
    margin-top: 30px;
    margin-bottom: 100px;
}

#section_welcome h3 {
    margin-top: 20px;
}

#pricing_customer {
    margin-bottom: 50px;
}

.pricing_col1 {
    padding: 20px;
    background-color: #7e8795;
}

.pricing_col2 {
    padding: 20px;
    background-color: #3c454f;
}

.pricing_col3 {
    padding: 20px;
    background-color: #7e8795;
}

#pricing_customer h3 {
    color: white;
}

#pricing_customer .subtitle {
    color: white;
}

#pricing_customer .info {
    color: white;
}

#pricing_customer .price {
    color: white;
    font-size: 40px;
    margin-top: 50px;
}

#pricing_customer .price_sub {
    color: white;
    font-size: 16px;
}

